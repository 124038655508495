import { Cart, ICart, ICartItem } from "models/cart";
import { action, computed, makeObservable, observable } from "mobx";
import { RootStore } from "./rootStore";

export default class CartStore {
  cartName: string = "siena_cart";
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeObservable(this, {
      cart: observable,
      getCartLength: computed,
      getCartItems: computed,
      addToCart: action,
      clearCart: action,
      deleteFromCart: action,
      setQuantity: action,
    });
  }

  initialize = (): ICart => {
    const cartInString = localStorage.getItem(this.cartName);
    if (cartInString) {
      return JSON.parse(cartInString) as ICart;
    } else {
      return new Cart();
    }
  };

  cart: ICart = this.initialize();

  get getCartLength() {
    return this.cart.items
      .map((item) => item.quantity)
      .reduce((a, b) => a + b, 0);

    //return this.cart?.items?.length || 0;
  }

  get getCartItems() {
    return this.cart?.items || [];
  }

  addToCart = (cartItem: ICartItem) => {
    const existingCartItem = this.cart.items.find(
      (item) => item.product.id === cartItem.product.id
    );
    if (existingCartItem) {
      this.cart.total -= existingCartItem.price;
      existingCartItem.quantity += cartItem.quantity;
      existingCartItem.price =
        existingCartItem.quantity * cartItem.product.price;
      this.cart.total += existingCartItem.price;
    } else {
      this.cart.items.push(cartItem);
      this.cart.total += cartItem.price;
    }

    // force update to observe computed value
    this.cart = { ...this.cart };
    localStorage.setItem(this.cartName, JSON.stringify(this.cart));
  };

  clearCart = () => {
    this.cart.items = [];
    this.cart.total = 0;
    localStorage.removeItem(this.cartName);
  };

  deleteFromCart = (productId: string) => {
    const idx = this.cart.items.findIndex(
      (item) => item.product.id === productId
    );
    if (idx > -1) {
      this.cart.total -= this.cart.items[idx].price;
      this.cart.items.splice(idx, 1);
    }
    localStorage.setItem(this.cartName, JSON.stringify(this.cart));
  };

  setQuantity = (productId: string, quantity: number) => {
    const cartItem = this.cart.items.find(
      (item) => item.product.id === productId
    );
    this.cart.total -= cartItem.price;
    cartItem.quantity = quantity;
    cartItem.price = quantity * cartItem.product.price;
    this.cart.total += cartItem.price;
    // force update to observe computed value
    this.cart = { ...this.cart };
    localStorage.setItem(this.cartName, JSON.stringify(this.cart));
  };
}
