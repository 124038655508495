import React, { Component } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Funfact = ({ style, subTitle, title, isContactUsBtn }) => {
  const { t } = useTranslation();

  const funfactArray = [
    {
      icon: "icon-glyph-142",
      countNumber: 175,
      title: "Award Winner",
    },
    {
      icon: "icon-glyph-226",
      countNumber: 275,
      title: "Visited Farms ",
    },
    {
      icon: "icon-glyph-2",
      countNumber: 324,
      title: "Happy Customers ",
    },
  ];

  const funfactMap = funfactArray.map((valu, i) => {
    return (
      <div className="col-md-4 col-sm-12" key={i}>
        <div className="facts_wrapper">
          <div className="icon-lay">
            <i className={`${valu.icon}`}></i>
          </div>
          <h3 className="stat-count count">{valu.countNumber}</h3>
          {/* <h5>{valu.title}</h5> */}
        </div>
      </div>
    );
  });

  return (
    <section
      className="video-section"
      style={
        style
        // {
        //   // backgroundImage: `url(
        //   //   "assets/images/siena_header_two.webp"
        //   // )`,
        //   // backgroundColor: "#623f28",
        //   // backgroundRepeat: "no-repeat",
        //   // backgroundSize: "cover",
        // }
      }
    >
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <div className="video_wrp">
              {/* <VideoPopup /> */}

              <p style={{ color: "white" }}>{subTitle}</p>
              <h2>{title}</h2>
            </div>
            {/* <div className="funfact_wapr row">{funfactMap}</div> */}
            <div
              className="funfact_wapr row"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <div className="slider_btn">
                <Link
                  to={isContactUsBtn ? "/kapcsolat" : "/termekek"}
                  className="slider_btn_one more-link"
                  style={{ background: "#e7b92c" }}
                >
                  {isContactUsBtn ? `${t("homePage.funfactButton.contact")}` : `${t("homePage.funfactButton.products")}`}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Funfact;
