import React, { Component } from "react";
import Slider from "react-slick";
import { useTranslation } from "react-i18next";

const ClinetCarousel = () => {
  const { t } = useTranslation();

  var settings = {
    autoplay: true,
    autoplaySpeed: 2000,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    // Responsive breakpoints
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 320,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  let imageTestiData = [
    {
      sponsorLogo: "ev_sportoloja_gala.webp",
    },
    {
      sponsorLogo: "pluss_kommando.webp",
    },
    {
      sponsorLogo: "forbes.webp",
    },
    {
      sponsorLogo: "hof.webp",
    },
    {
      sponsorLogo: "voroskereszt.webp",
    },
    {
      sponsorLogo: "lupa_winter_run.webp",
    },
    {
      sponsorLogo: "posh.webp",
    },
  ];

  let ImageeTestiDataList = imageTestiData.map((val, i) => {
    return (
      <div className="AA" key={i}>
        <div className="col-md-12">
          <div className="client-box">
            <img
              style={{ height: "100px" }}
              src={`assets/images/${val.sponsorLogo}`}
              alt=""
            />
          </div>
        </div>
      </div>
    );
  });

  return (
    <div style={{ margin: "10px" }}>
      <div className="client-section">
        <div className="container">
          <div className="base-header">
            {/* <small>Siena</small> */}
            <h3>{t("homePage.references.title")}</h3>
          </div>
          <Slider {...settings}>{ImageeTestiDataList}</Slider>
        </div>
      </div>
    </div>
  );
};

export default ClinetCarousel;
