import React, { Component, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { IProduct } from "models/product";
import agent from "api/agent";
import { getProductName } from "utils/helpers";
import { useRootStore } from "mobx/rootStore";
import { CartItem } from "models/cart";
import { showSuccessToast } from "utils/customToaster";

const BestSellers = () => {
  const navigate = useNavigate();
  const {
    isProductWishlisted,
    addProductToWishlist,
    removeProductFromWishlist,
  } = useRootStore().wishlistStore;
  const { addToCart } = useRootStore().cartStore;

  const { t, i18n } = useTranslation();
  const [products, setProducts] = useState<IProduct[]>([]);

  useEffect(() => {
    agent.Product.getMostLikedProducts().then((products: IProduct[]) => {
      setProducts(products);
    });
  }, [setProducts]);

  const goToSingleProduct = (productId: string) =>
    navigate({
      pathname: "/termek",
      search: `?id=${productId}`,
    });

  const handleAddOrRemoveProductToWishlist = (product: IProduct) => {
    if (isProductWishlisted(product.id)) {
      removeProductFromWishlist(product.id);
    } else {
      addProductToWishlist(product);
    }
  };

  const handleAddToCart = (product: IProduct) => {
    var cartItem = new CartItem();
    cartItem.quantity = 1;
    cartItem.price = product.price;
    cartItem.product = product;
    addToCart(cartItem);
    showSuccessToast("Kosárhoz adva!");
  };

  if (!(products && products.length > 0)) {
    return null;
  }

  return (
    <section className="product-section">
      <div className="container">
        <div className="base-header">
          {/* <small> Siena</small> */}
          <h3>{t("homePage.bestsellers.title")}</h3>
        </div>
        <div className="row">
          {products.map((product: IProduct) => (
            <div className="col-md-3 col-sm-12" key={product.id}>
              <div
                className="product_wrp"
                style={{
                  backgroundImage:
                    "url(assets/images/siena_basic_background.webp)",
                }}
              >
                <div className="product_img">
                  <img
                    src={`${product.images[0]?.thumbnailLink}`}
                    alt="product"
                  />
                </div>
                <div className="product_info">
                  <h4>{getProductName(product, i18n.language)}</h4>
                  <span className="product_price">{product.price} Ft</span>
                </div>
                <div className="project_view">
                  <div
                    style={{ background: "#f0f0f0", cursor: "pointer" }}
                    onClick={() => handleAddToCart(product)}
                  >
                    <i className="icon-glyph-13"></i>
                  </div>
                  <div
                    onClick={() => handleAddOrRemoveProductToWishlist(product)}
                    className="project-div"
                    style={{
                      background: `${isProductWishlisted(product.id) ? "#ddc048" : "#f0f0f0"
                        }`,
                      cursor: "pointer",
                    }}
                  >
                    <i className="icon-glyph-52"></i>
                  </div>
                </div>
                <input
                  style={{ marginTop: "10px" }}
                  type="button"
                  onClick={() => goToSingleProduct(product.id)}
                  className="submit-contact submitBnt"
                  value={t("products.button")}
                />
              </div>
            </div>
          ))}
        </div>
        <div
          className="funfact_wapr row"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <div className="slider_btn">
            <Link
              to="/termekek"
              className="slider_btn_one more-link"
              style={{ background: "#e7b92c", textTransform: "none" }}
            >
              {t("homePage.bestsellers.button")}
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BestSellers;
