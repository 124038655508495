export interface IRecipe {
    id: string;
    name: string;
    img: string;
    preparationTime: string;
    ingredients: IIngredient[];
    preparation: string[];
}

export class Recipe implements IRecipe {
    id: string;
    name: string;
    img: string;
    preparationTime: string;
    ingredients: IIngredient[];
    preparation: string[];

    constructor(id: string, name: string, img: string, preparationTime: string, ingredients: IIngredient[], preparation: string[]) {
        this.id = id;
        this.name = name;
        this.img = img;
        this.preparationTime = preparationTime;
        this.ingredients = ingredients;
        this.preparation = preparation
    }
}

export interface IIngredient {
    name: string;
    value: string
}

export class Ingredient implements IIngredient {
    name: string;
    value: string;

    constructor(name: string, value: string) {
        this.name = name;
        this.value = value;
    }
}