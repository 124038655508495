import { InteractionRequiredAuthError } from '@azure/msal-browser';
import { msalInstance, scopes } from 'config/authConfig';

export const acquireAccessToken = async (): Promise<string> => {
    const activeAccount = msalInstance.getActiveAccount();
const accounts = msalInstance.getAllAccounts();

    if (!activeAccount && accounts.length === 0) {
        return null;
    }

    const authRequest = {
        scopes: scopes,
        account: accounts[0],
    };

    let authResponse;

    try {
        authResponse = await msalInstance.acquireTokenSilent(authRequest);
    } catch (error) {
        if (error instanceof InteractionRequiredAuthError) {
            if (authRequest.account) {
                msalInstance.loginRedirect({ scopes: authRequest.scopes })
            }
        }
    }

    return authResponse?.accessToken;
};
