import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import agent from "../api/agent";

const GalleryContent = () => {
  const [instagramData, setInstagramData] = useState([]);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    agent.Instagram.getImages()
      .then((instagramData) => {
        setInstagramData(instagramData.data);
      })
      .finally(() => setLoading(false));
  }, []);

  const settings = {
    autoplay: true,
    autoplaySpeed: 2000,
    dots: false,
    arrows: false,
    infinite: true,
    centerMode: true,
    centerPadding: "200px",
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: "80px",
          arrows: false,
          dots: false,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          centerPadding: "40px",
          centerMode: false,
          arrows: false,
          dots: false,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          centerMode: false,
          arrows: false,
          dots: false,
        },
      },
      {
        breakpoint: 580,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false,
          arrows: false,
          dots: false,
        },
      },
    ],
  };

  if (instagramData.length === 0) {
    return null;
  }

  return (
    <section className="project-section">
      <div className="container-fluid">
        <div className="project_list_one">
          <div className="container">
            <div className="base-header">
              {/* <small>Siena</small> */}
              <h3> Galéria </h3>
            </div>
          </div>
          <div className="project_slider_one">
            <Slider {...settings}>
              {instagramData.slice(0, 10).map((imgDataElement, i) => (
                <a
                  className="footer_social_custom"
                  href="https://www.instagram.com/sienamagyarorszag/"
                  target="_blank"
                  rel="noopener noreferrer"
                  key={i}
                >
                  <div className="col-md-12 swiper-slide service-gallery__single-slide">
                    <div className="project-item item">
                      <div className="project_slide_img item">
                        <img
                          src={imgDataElement.media_url}
                          style={{ maxHeight: "500px" }}
                          alt="product"
                        />
                      </div>
                    </div>
                  </div>
                </a>
              ))}
            </Slider>
          </div>
          {/* <div className="project_btn text-center">
              <Link to="gallery" className="more-link">
                View More
              </Link>
            </div> */}
        </div>
      </div>
    </section>
  );
};

export default GalleryContent;
