import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { renderRoutes } from "navigation/routes";
import { Spinner } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.min.css";
import { ToastContainer } from "react-toastify";
import ScrollToTop from "helpers/ScrollToTop";
import CookiePopup from "components/CookiePopup";
import TagManager from "react-gtm-module";

const App: React.FC = () => {
  useEffect(() => {
    TagManager.initialize({ gtmId: process.env.REACT_APP_GTM_CODE });
  }, []);

  return (
    <React.Suspense
      fallback={
        <div className="center-screen">
          <Spinner animation="border" />
        </div>
      }
    >
      {/* <ErrorBoundary FallbackComponent={ErrorFallback}> */}
      <HelmetProvider>
        <Router>
          <ScrollToTop>
            <Routes>{renderRoutes()}</Routes>
          </ScrollToTop>
        </Router>
        <CookiePopup />
        <ToastContainer />
      </HelmetProvider>
      {/* </ErrorBoundary> */}
    </React.Suspense>
  );
};

export default App;
