import { createContext, useContext } from 'react';
import { configure } from 'mobx';
import FrontEndStore from 'mobx/frontEndStore';
import OrderStore from './orderStore';
import ProductStore from './productStore';
import WishlistStore from './wishlistStore';
import CartStore from './cartStore';
import RecipeStore from './recipeStore';
import SimplePayStore from './simplePayStore';

configure({ enforceActions: 'always' });

export class RootStore {
    frontEndStore: FrontEndStore;
    productStore: ProductStore;
    orderStore: OrderStore;
    wishlistStore: WishlistStore;
    cartStore: CartStore;
    recipeStore: RecipeStore;
    simplePayStore: SimplePayStore;

    constructor() {
        this.frontEndStore = new FrontEndStore(this);
        this.productStore = new ProductStore(this);
        this.orderStore = new OrderStore(this);
        this.wishlistStore = new WishlistStore(this);
        this.cartStore = new CartStore(this);
        this.recipeStore = new RecipeStore(this);
        this.simplePayStore = new SimplePayStore(this);
    }
}

const rootStore = new RootStore();
export const RootStoreContext = createContext(rootStore);

export function useRootStore(): RootStore {
    return useContext(RootStoreContext);
}
