import React, { Component, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Swiper from "react-id-swiper";
import "swiper/css/swiper.css";
import { useTranslation } from "react-i18next";

const Slides = () => {
  const { t, i18n, ready } = useTranslation();

  let slideImagesHU = [
    {
      img: "assets/images/siena_header_one.webp",
      smallTitle: "Csoki háttér",
      title: "Mennyei csokis finomságok egészséges, mentes változatban is!",
      description: "Üdvözöljük a Siena Magyarország weboldalán",
    },
    {
      img: "assets/images/siena_header_two.webp",
      smallTitle: "Édesen szép az élet...",
      title: "Édesség a család minden tagjának!",
      description: "Üdvözöljük a Siena Magyarország weboldalán",
    },
  ];

  let slideImagesEN = [
    {
      img: "assets/images/siena_header_one.webp",
      smallTitle: "Chocolate background",
      title: "Healthy heavenly chocolate sweets",
      description: "Welcome to the official page of Siena Hungary",
    },
    {
      img: "assets/images/siena_header_two.webp",
      smallTitle: "Life is only beautiful when it is sweet...",
      title: "Sweets for the whole family",
      description: "Welcome to the official page of Siena Hungary",
    },
  ];

  const properties = {
    slidesPerView: 1,
    loop: true,
    speed: 1000,
    watchSlidesVisibility: true,
    effect: "slide",
    pagination: {
      el: ".swiper-pagination",
      type: "bullets",
      clickable: true,
    },
    navigation: {
      nextEl: ".st-swiper-button-next",
      prevEl: ".st-swiper-button-prev",
    },
    renderPrevButton: () => (
      <div className="st-swiper-button-prev st-swiper-button-nav d-none d-xl-block">
        <i className="icon-glyph-205" />
      </div>
    ),
    renderNextButton: () => (
      <div className="st-swiper-button-next st-swiper-button-nav d-none d-xl-block">
        <i className="icon-glyph-204" />
      </div>
    ),
    autoplay: {
      delay: 5000,
    },
  };

  let ImageGalleryDataListEN = slideImagesEN.map((val, i) => {
    return (
      <div
        className="single_slider"
        style={{ backgroundImage: `url(${val.img})` }}
        key={i}
      >
        <div className="slider_item_tb">
          <div className="slider_item_tbcell">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 col-sm-12">
                  {/* <h5 style={{ color: "white" }}>{val.smallTitle}</h5> */}
                  <h2 style={{ color: "white", textShadow: "3px 1px black" }}>{val.title}</h2>
                  <p style={{ color: "white", fontSize: "20px", textShadow: "3px 1px black" }}>
                    {val.description}
                  </p>
                  <div className="slider_btn animated fadeInDown">
                    <Link to="/termekek" className="slider_btn_one more-link ">
                      Products
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  });

  let ImageGalleryDataListHU = slideImagesHU.map((val, i) => {
    return (
      <div
        className="single_slider"
        style={{ backgroundImage: `url(${val.img})` }}
        key={i}
      >
        <div className="slider_item_tb">
          <div className="slider_item_tbcell">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 col-sm-12">
                  {/* <h5 style={{ color: "white" }}>{val.smallTitle}</h5> */}
                  <h2 style={{ color: "white", textShadow: "3px 1px black" }}>{val.title}</h2>
                  <p style={{ color: "white", fontSize: "20px", textShadow: "3px 1px black" }}>
                    {val.description}
                  </p>
                  <div className="slider_btn animated fadeInDown">
                    <Link to="/termekek" className="slider_btn_one more-link ">
                      Termékeink
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  });

  return (
    <div className="slides_wrapper">
      <div className="slider_home">
        {ready && i18n.language.includes("hu") && (
          <Swiper {...properties}>{ImageGalleryDataListHU}</Swiper>
        )}
        {ready && i18n.language.includes("en") && (
          <Swiper {...properties}>{ImageGalleryDataListEN}</Swiper>
        )}
      </div>
    </div>
  );
};

export default Slides;
