import { showSuccessToast } from "utils/customToaster";
import { action, computed, makeObservable, observable } from "mobx";
import { RootStore } from "./rootStore";
import { IProduct } from "models/product";

export default class WishlistStore {
  wishlistName: string = "siena_wishlist";
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeObservable(this, {
      products: observable,
      getWishlistProducts: computed,
      addProductToWishlist: action,
      removeProductFromWishlist: action,
      clearWishlist: action,
    });
  }

  initialize = (): IProduct[] => {
    const wishlistInString = localStorage.getItem(this.wishlistName);
    if (wishlistInString) {
      return JSON.parse(wishlistInString) as IProduct[];
    } else {
      return [];
    }
  };

  products: IProduct[] = this.initialize();

  get getWishlistProducts(): IProduct[] {
    return this.products;
  }

  addProductToWishlist = (product: IProduct) => {
    if (this.products?.some((p) => p.id === product.id)) {
      showSuccessToast("A termék már kívánságlistához adva!");
      return;
    }

    this.products.push(product);
    localStorage.setItem(this.wishlistName, JSON.stringify(this.products));
  };

  removeProductFromWishlist = (productId: string) => {
    this.products = this.products.filter((p) => p.id !== productId);
    showSuccessToast("Törölve a kivánságlistáról!");
    localStorage.setItem(this.wishlistName, JSON.stringify(this.products));
  };

  isProductWishlisted = (productId: string): boolean => {
    if (this.getWishlistProducts && Array.isArray(this.getWishlistProducts)) {
      return this.getWishlistProducts.some((wp) => wp.id === productId);
    }

    return false;
  };

  clearWishlist = () => {
    localStorage.removeItem(this.wishlistName);
    this.products = [];
  };
}
