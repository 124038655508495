import { computed, observable, action, makeObservable } from 'mobx';
import agent from 'api/agent';
import { RootStore } from 'mobx/rootStore';
import { IProduct } from 'models/product';

export default class ProductStore {
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        makeObservable(this);

        this.rootStore = rootStore;
    }

    @observable selectedProduct: IProduct | null = null;
    @observable selectedProductId: string | null = null;
    @observable products: Map<string, IProduct> = new Map();

    @computed get getProducts(): IProduct[] {
        return Array.from(this.products.values());
    }

    @action setSelectedProductId = (id: string): void => {
        this.selectedProductId = id;
    };


    @action setSelectedProduct = (product: IProduct): void => {
        this.selectedProduct = product;
    };

    @action loadSelectedProduct = async (): Promise<IProduct> => {
        if (!this.selectedProductId) {
            throw new Error('selectedProductId was undefined!');
        }

        const store = await agent.Product.getProductById(this.selectedProductId);
        this.selectedProduct = store;
        return store;
    };
}
