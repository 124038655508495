import React, { Component } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ProductCategories = () => {
  const { t } = useTranslation();

  const serviceListArray = [
    {
      image: "assets/images/siena_tejcsoki.webp",
      title: `${t("homePage.productCategories.category1.title")}`,
      description: `${t("homePage.productCategories.category1.description")}`,
      link: "termekek",
    },
    {
      image: "assets/images/siena_mogyorokrem.webp",
      title: `${t("homePage.productCategories.category2.title")}`,
      description: `${t("homePage.productCategories.category2.description")}`,
      link: "termekek",
    },
    {
      image: "assets/images/siena_kakaopor.webp",
      title: `${t("homePage.productCategories.category3.title")}`,
      description: `${t("homePage.productCategories.category3.description")}`,
      link: "termekek",
    },
    {
      image: "assets/images/siena_roletti.webp",
      title: `${t("homePage.productCategories.category4.title")}`,
      description: `${t("homePage.productCategories.category4.description")}`,
      link: "termekek",
    },
  ];

  const serviceListtMap = serviceListArray.map((valu, i) => {
    return (
      <div className="col-md-3 col-sm-12" key={i}>
        {/* <Link to={`${valu.link}`}> */}
        <div className="service-item">
          <div className="img_serv">
            <img
              src={`${valu.image}`}
              // style={{
              //   height: `${valu.height}px`,
              // }}
              alt="siena-csokolade"
            />
          </div>
          <div className="service_text">
            <Link to={`${valu.link}`}>
              <h4 style={{ wordBreak: "break-all" }}>{valu.title}</h4>
              <p>{valu.description}</p>
            </Link>
          </div>
          <Link to={`${valu.link}`} className="serv_link">
            <i className="icon-glyph-40"></i>
          </Link>
        </div>
        {/* </Link> */}
      </div>
    );
  });

  return (
    <section
      className="service-section"
      style={{
        backgroundImage: "url(assets/images/siena_basic_background.webp)",
      }}
    >
      <div className="container">
        {/* Heading */}
        <div className="base-header">
          {/* <small>Siena</small> */}
          <h3>{t("homePage.productCategories.title")}</h3>
        </div>
        {/* End: Heading */}

        <div className="row">{serviceListtMap}</div>
      </div>
    </section>
  );
};

export default ProductCategories;
