import { IProduct } from "models/product";

export const getProductName = (product: IProduct, language: string): string => {
    if (language.includes("hu")) {
      return product.name;
    }

    return product.englishName || product.name;
};

export const getProductDescription = (product: IProduct, language: string): string => {
    if (language.includes("hu")) {
      return product.description;
    }

    return product.englishDescription || product.description;
};

export const getProductAdditionalInformation = (product: IProduct, language: string): string => {
    if (language.includes("hu")) {
      return product.additionalInformation;
    }

    return product.englishAdditionalInformation || product.additionalInformation;
};
