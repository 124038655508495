import React from "react";
import sienaChooseUs from "../assets/images/siena_why_choose_us.webp";
import { useTranslation } from "react-i18next";

const ChooseUs = () => {
  const { t } = useTranslation();

  return (
    <section
      className="whychose-section"
      //   style={{
      //     backgroundImage: `url(${
      //       process.env.PUBLIC_URL + "/assets/images/bg_1.jpg"
      //     })`,
      //   }}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-5 col-sm-12">
            <div
              className="whychose_bg"
              style={{
                backgroundImage: `url(${sienaChooseUs})`,
              }}
            ></div>
          </div>
          <div className="col-lg-7 col-sm-12 my-auto">
            <div className="whychose_wrp">
              {/* Start:  Header Section */}
              <div className="base-header base_header_left">
                {/* <small>Siena</small> */}
                <h3>{t("homePage.chooseUs.why")}</h3>
              </div>
              {/* End: Header Section */}
              <div className="special_ser_item">
                <div className="special_ser_icon">
                  <i className="icon-glyph-268"></i>
                </div>
                <h4>{t("homePage.chooseUs.reason1.header")}</h4>
                <p style={{ textAlign: "justify" }}>
                  {t("homePage.chooseUs.reason1.description")}
                </p>
              </div>
              <div className="special_ser_item">
                <div className="special_ser_icon">
                  <i className="icon-glyph-268"></i>
                </div>
                <h4>{t("homePage.chooseUs.reason2.header")}</h4>
                <p style={{ textAlign: "justify" }}>
                  {t("homePage.chooseUs.reason2.description")}
                </p>
              </div>
              <div className="special_ser_item">
                <div className="special_ser_icon">
                  <i className="icon-glyph-268"></i>
                </div>
                <h4>{t("homePage.chooseUs.reason3.header")}</h4>
                <p style={{ textAlign: "justify" }}>
                  {t("homePage.chooseUs.reason3.description")}
                </p>
              </div>
              <div className="special_ser_item">
                <div className="special_ser_icon">
                  <i className="icon-glyph-268"></i>
                </div>
                <h4>{t("homePage.chooseUs.reason4.header")}</h4>
                <p style={{ textAlign: "justify" }}>
                  {t("homePage.chooseUs.reason4.description")}
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* row */}
      </div>
      {/* container */}
    </section>
  );
};

export default ChooseUs;
