import agent from 'api/agent';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { ApplicationRole } from 'models/enums/applicationRole';
import { OrderStatus } from 'models/enums/orderStatus';
import { IOrder, IPayment } from 'models/order';
import { RootStore } from './rootStore';

export default class OrderStore {
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        makeObservable(this);
    }

    @observable orders: Map<string, IOrder> = new Map();
    @observable selectedOrder: IOrder = null;

    @computed get getOrders(): IOrder[] {
        return Array.from(this.orders.values());
    }

    @computed get getSelectedOrder(): IOrder {
        return this.selectedOrder;
    }
}
