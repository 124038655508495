import React, { Fragment } from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import LayoutOne from "../layouts/LayoutOne";

const NotFound = () => {
  return (
    <Fragment>
      <Helmet>
        <title>Siena | 404</title>
        <meta name="description" content="Siena 404" />
      </Helmet>
      <LayoutOne>
        <div
          className="errorPage"
          style={{
            backgroundImage: `url(${
              process.env.PUBLIC_URL + "/assets/images/bg_3.jpg"
            })`,
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-sm-12 text-center">
                <h2>OOPS!</h2>
                <span>404 - Oldal nem található</span>
                <p>
                  The page you are looking for was moved, removed, renamed or
                  might never existed.
                </p>
                <Link className="more-link" to="/">
                  Vissza a Főoldalra
                </Link>
              </div>
            </div>
          </div>
        </div>
      </LayoutOne>
    </Fragment>
  );
};

export default NotFound;
