import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
} from "mobx";
import { Ingredient, IRecipe, Recipe } from "models/recipe";
import { RootStore } from "./rootStore";

export const recipesHU: IRecipe[] = [
  new Recipe(
    "grapefruitos-truffelgolyo",
    "Grapefruitos trüffelgolyó",
    "assets/images/siena_grapefruitos_truffelgolyo.webp",
    "Elkészítési idő: 15 perc + 1 óra hűlési idő",
    [
      new Ingredient("Siena 60%-os cukormentes étcsokoládé", "20 dkg"),
      new Ingredient("Állati habtejszín", "50 ml"),
      new Ingredient("Frissen facsart grapefruitlé", "100 ml"),
      new Ingredient("Puha vaj", "5 dkg"),
      new Ingredient("Nagy csipet só", "1"),
      new Ingredient(
        "Cukrozatlan 20-22%-os Siena kakaópor a hempergetéshez",
        "1"
      ),
    ],
    [
      "A tejszínt és a grapefruitlét a sóval egy kisebb fazékban feltesszük melegíteni, közepes lángon. A csokoládét egy műanyag tálba törjük, célszerű minél kisebb darabokra. Ha a tejszínes mix felforrt, lehúzzuk a tűzről és óvatosan ráöntjük a kimért csokira. Egy kézi habverővel addig keverjük, amíg teljesen homogén krémet nem kapunk. Ha kézmeleg, hozzáadjuk a puha vajat is, teljesen simára keverjük és hűtőbe tesszük minimum 1 órára, hogy formázható legyen. ",
      "Vizes kézzel golyókat formázunk. Nem baj, ha szabálytalanok, annál szebben fognak mutatni egy halomban. Megforgatjuk mindegyiket a kakaóporban, duplán, hogy szép legyen a felülete. ",
    ]
  ),
  new Recipe(
    "kokusz-panna-cotta",
    "Kókusz panna cotta, lime, sült sós kakaós morzsa",
    "assets/images/siena_kokusz_panna_cotta.webp",
    "Elkészítési idő: 30 percc+ dermedési idő",
    [
      new Ingredient("Kókusztejszín", "6 dl"),
      new Ingredient("Kristálycukor", "7 dkg"),
      new Ingredient("Vaníliarúd kikapart magjai", "fél"),
      new Ingredient("Lime reszelt héja", "1 db"),
      new Ingredient("Zselatin", "5 lap"),
      new Ingredient("Só", "1 nagy csipet"),
      new Ingredient("Liszt", "5 dkg"),
      new Ingredient("Mandulaliszt", "5 dkg"),
      new Ingredient("Hideg vaj", "5 dkg"),
      new Ingredient("20-22%-os Siena kakaópor", "5 dkg"),
    ],
    [
      "A panna cottához a zselatint egy műanyag tálban beáztatjuk úgy, hogy bőven ellepje jéghideg csapvíz. ",
      "A tejszínt, cukrot, vaníliarúd magjait, lime héját és a sót összemérjük egy magasabb falú lábasban. Forráspontig melegítjük, odafigyelve, hogy a cukor ne égjen le. Ezután lehúzzuk a tűzről és a zselatint, ami addigra már szivacsos állagúra duzzadt, kicsavarjuk kézzel és beledobjuk a forró alapba. Elkeverjük az egészet és picit hűlni hagyjuk, mielőtt formába töltjük. ",
      "Addig a ropogóshoz bekapcsoljuk a sütőt 180 fok, légkeverésre.  Egy tálban összemorzsoljuk kézzel az összes hozzávalót és egy sütőpapíros tepsire kiterítjük. Betesszük a sütőbe 7 percre. Ha letelt, egy villával átforgatjuk és még kb. 3-4 percet pirítjuk, odafigyelve, hogy ne égjen meg. Ha készen van, hűtjük.",
      "Utolsó lépésként szép poharakban szétosztjuk a panna cottát. Hűtőbe tesszük legalább 2 órára, de minél többet pihen, annál jobb. Akkor jó, ha szobahőmérsékleten remeg, mint a kocsonya, de közben szétolvad a szánkban. ",
      "Tálaláskor a tetejére halmozunk kanállal a ropogósból. ",
    ]
  ),
  new Recipe(
    "csokis-pofeteg",
    "Csokis pöfeteg",
    "assets/images/siena_csokis_pofeteg.webp",
    "Elkészítési idő: 30 perc + sütési idő",
    [
      new Ingredient("Siena mogyorós étcsokoládé", "10 dkg"),
      new Ingredient("Puha vaj", "5 dkg"),
      new Ingredient("Szobahőmérsékletű tojás", "1 db"),
      new Ingredient("Porcukor", "7 dkg"),
      new Ingredient("Darált pörkölt törökmogyoró", "5 dkg"),
      new Ingredient("Liszt", "7 dkg"),
      new Ingredient("Sütőpor", "1 kávéskanál"),
      new Ingredient("Só", "1 nagy csipet"),
    ],
    [
      "A mogyorós étcsokit egy éles késsel felaprítjuk, majd vízgőz fölött megolvasztjuk óvatosan. A puha vajat kihabosítjuk a cukorral, szobahőmérsékletű tojással. Legcélszerűbb, ha kézi gépi habverőt használunk. Hozzáöntjük a már kihűlt étcsokit és utána az összes többi maradék alapanyaggal alaposan kikeverjük a masszát. Hűtőbe tesszük pihenni fél órára, hogy könnyebb legyen majd formázni.",
      "Diónyi nagyságú golyókat formázunk vizes kézzel, megforgatjuk porcukorban és sütőpapíros tepsire tesszük őket úgy, hogy legalább 3-4 cm távolság legyen köztük.",
      "180 fokos előmelegített sütőbe tesszük és 7-9 percig sütjük. Fontos, hogy ne süssük túl, mert kiszárad. Akkor tökéletes az állaga, ha a külső réteg már masszívabb, de megtapintva érezzük, hogy belül még lágy. Így kihűlés után tökéletes lesz.",
    ]
  ),
  new Recipe(
    "tart-kosarka",
    "Tart kosárka, házi főzött kávés csoki krémmel és málnával",
    "assets/images/siena_tart_kosarka.webp",
    "Elkészítési idő: 2 óra",
    [
      new Ingredient("Liszt", "15 dkg"),
      new Ingredient("Mandulaliszt", "5 dkg"),
      new Ingredient("Hideg vaj", "10 dkg"),
      new Ingredient("Porcukor", "5 dkg"),
      new Ingredient("Só", "1 csipet"),
      new Ingredient("Jéghideg víz", "2 ek"),
      new Ingredient("Citrom reszelt héja", "1 db"),
      new Ingredient("Zsíros tej", "250 ml"),
      new Ingredient("Cukor", "80 g"),
      new Ingredient("Tojássárgája", "5 db"),
      new Ingredient("Rúd vanília", "1 db"),
      new Ingredient("Siena 60%-os étcsokoládé", "150 g"),
      new Ingredient("Egész kávé megtörve", "1 marék"),
      new Ingredient("Fagyasztott málna", "20 dkg"),
      new Ingredient("Kristálycukor", "5 dkg"),
      new Ingredient("Keményítő", "1 csapott evőkanál"),
      new Ingredient("Hideg víz", "1 adag"),
    ],
    [
      "A tészta hozzávalóit gyors mozdulatokkal összemorzsolom majd összegyúrom egy tálban és fóliába csomagolva legalább fél órára fagyasztóba teszem. ",
      "Ezután a tésztát enyhén lisztes felületen kinyújtjuk kb. 5 mm vékonyságúra és beleszaggatjuk a formákba, majd fagyasztóba tesszük újabb 30 percre. Így nem kell használnunk sütőbabot a sütéshez. ",
      "Ha ez megvan, 200 fokos előmelegített, légkeveréses sütőbe toljuk és készre, aranybarnára sütjük 7-10 perc alatt.",
      "Még melegen kivesszük a formákból és hagyjuk teljesen kihűlni. ",
      "A krémhez a tejet felforrósítjuk egy lábasban a sóval, vaníliarúd magjaival, tört kávébabbal együtt. Közben a tojás sárgáját a cukorral jól kihabosítjuk egy habverővel. A meleg tejet apránként, kis adagokban hozzáadjuk a cukros tojássárgához és ha már langyos, vagyis kiegyenlítettük a hőt, akkor visszaöntjük az egészet a lábasba és óvatosan, kevergetve, alacsony lángon puding sűrűségűre főzzük. A hőmérséklete ne legyen több 82 foknál, különben megsütjük a sárgáját, kicsapódik és tojásrántotta lesz belőle. Vízgőz felett is lehet dolgozni, de akkor egy picit hosszabb ideig tart a folyamat. Ha készen van, azonnal átöntjük szitán keresztül egy műanyag edénybe és hozzáadjuk az aprított étcsokoládét, majd homogénre keverjük az egészet. Amíg hűl lefedjük frissen tartó fóliával így nem fog megbőrösödni.",
      "A málnát  cukorral és sóval, kis lángon felfőzzük. Ezután keményítőt keverünk el egy kis tálban annyi hideg vízzel, hogy csomómentes legyen, majd a forró málnához adjuk és forralunk rajta egyet. Ha vizesebb volt a gyümölcs és még lágyabb a szósz, bátran tegyünk hozzá még keményítőt. A cél egy sűrűbb lekváros állag. ",
      "Ha készen vagyunk az összes fázissal és kihűlt minden, akkor a tartot/tartokat megkenjük a málnával, majd akár habzsák segítségével megtöltjük a csoki krémmel. ",
      "Díszíthetjük étcsoki forgáccsal és friss málnával.",
    ]
  ),
  new Recipe(
    "mogyoros-etcsokis-szelet",
    "Cukor és gluténmentes mogyorós-étcsokis szelet",
    "assets/images/siena_mogyoros_etcsokis_szelet.webp",
    "Elkészítési idő: 2 óra",
    [
      new Ingredient("Egész tojás", "4 db"),
      new Ingredient("Törökmogyoró pirítva és darálva", "5 dkg"),
      new Ingredient("Mandulaliszt", "5 dkg"),
      new Ingredient("Rízsiszt", "45 g"),
      new Ingredient("Eritrit", "50 g"),
      new Ingredient("Só", "2 nagy csipet"),
      new Ingredient("Siena cukormentes 60%-os étcsokoládé", "5 dkg"),
      new Ingredient("Tojássárgája", "2 db"),
      new Ingredient("Habtejszín", "2,5 dl"),
      new Ingredient("Hideg tejeszín", "3 dl"),
      new Ingredient("Mascarpone", "20 dkg"),
      new Ingredient("Siena Duó Mix cukormentes tejkrém", "40 g"),
      new Ingredient("Cukrozatlan amerikai mogyoróvaj", "15 g"),
      new Ingredient("Zselatin", "4 lap"),
    ],
    [
      "A piskótához a tojásokat szétválasztjuk. A fehérjét gépi habverővel habbá verjük. Amikor elkezd a tömege nőni, apránként hozzáadjuk az édesítőt és végül a sót. Kézzel hozzákeverjük a sárgájákat és végül a száraz alapanyagokat, de nagyon óvatosan dolgozunk, hogy ne törjön meg a hab. A tepsit kibéleljük sütőpapírral és belehalmozzuk a masszát majd egy kenőkéssel egyenletesen szétoszlatjuk. 190 fokos, előmelegített sütőben kb. 12 perc alatt készre sütjük, de tűpróbával ellenőrizzük. Ha készen van, kivesszük a sütőből, kifordítjuk a tepsiből és lehúzzuk róla a sütőpapírt, hogy ne vizesedhessen be a tészta.",
      "A csoki krémhez az étcsokit megolvasztjuk vízgőz fölött, majd félre tesszük hűlni. Egy kis lábasban az édesítő és a vizet összemérjük és 118 fokra hevítjük, hogy cukorszirup készüljön belőle. Eközben géppel habbá verjük a sárgájákat a csipet sóval, és mikor a szirup eléri a 118 fokot, óvatosan hozzácsurgatjuk a szirupot, kis fokozat mellett. Ha benne van az összes szirup, felkapcsoljuk magas fokozatra a gépet és kihűlésig habbá verjük. Ha már szép magas, és kifehéredett az egész, készen van. Külön, habbá verjük a hideg tejszínt és óvatosan összedolgozzuk a kettő alapot. Végül az egészet beleforgatjuk a már kihűlt megolvasztott étcsokiba. Hűtőbe tesszük, hogy összeállhasson. ",
      "A mogyorós krémhez 2 dl hideg tejszínt és a mascarponét gépi habverővel kihabosítjuk. Hozzáadjuk a mogyoróvajat, a tejkrémet és a sót is.  Egy kis lábasban az 1 dl tejszínt felforrósítjuk az eritrittel együtt és közben hideg vízbe áztatjuk a zselatint. Ha éppen, hogy nem forrt fel a tejszín, lehúzzuk a tűzről és beletesszük a kicsavart zselatint. Elkeverjük és a kihabosított mascarponés krémből kiveszünk 2-3 kanállal, amit ehhez a forró zselatinos alaphoz keverünk. Így kiegyenlítjük a hőt és a zselatin biztos hogy nem ugrik össze a hideg hatására. Ezután az zselatinos részt egy spatulával hozzáadjuk a mascarponés krémhez. ",
      "Összeállítjuk a sütit. A piskótát egy recés nagy késsel hosszában kettő egyenlő részre vágjuk. ",
      "Az egyik lapot visszatesszük a tepsibe és megtöltjük egyenletesen a csokis krémmel. Utána jöhet a második tészta és végül ráhalmozzuk a mogyorós krémet is. ",
      "Dekorálhatjuk pirított egész vagy szeletelt mandulával. ",
    ]
  ),
  new Recipe(
    "lisztmentes-csoki-torta",
    "Lisztmentes csoki torta, maracujás tejcsoki mousse",
    "assets/images/siena_marajucas_tejcsoki_mousse.webp",
    "Elkészítési idő: 1 óra + hűlés, dermedés ",
    [
      new Ingredient("Siena cukormentes 60%-os étcsokoládé", "11 dkg"),
      new Ingredient("Vaj", "8,5 dkg"),
      new Ingredient("Só", "2 nagy csipet"),
      new Ingredient("Siena 20-22%-os kakaópor", "4 dkg"),
      new Ingredient("Mandulaliszt", "5 dkg"),
      new Ingredient("Kristálycukor", "15 dkg"),
      new Ingredient("Tojás", "3 db"),
      new Ingredient("Siena tejcsokoládé", "20 dkg"),
      new Ingredient("Állati habtejszín", "4 dl"),
      new Ingredient("Friss maracuja húsa kikaparva", "2 db"),
      new Ingredient("Zselatin", "5 lap"),
    ],
    [
      "Egy közepes méretű fazékba 2 ujjnyi vastagságú vizet töltünk és felmelegítjük. Egy fém tálba vagy üstbe belemérjük a csokit, vajat, sót, rátesszük a vízgőzre és lassan összeolvasztjuk, időnként megkeverve. ",
      "A kakaóport, mandulát összemérjük egy kis tálban. Ha megolvadt a vajas keverék, hozzáöntjük a száraz összetevőket és egy kézi habverővel csomómentesre keverjük az egészet. ",
      "Robotgéppel jó habosra verjük az egész tojásokat a cukorral együtt és végül óvatosan, több lépésben beleforgatjuk a csokis részbe. ",
      "Szétosztjuk 2 db 20 cm-es tortaformába és 160 fokra előmelegített, légkeveréses sütőben készre sütjük kb.12-17 perc alatt. Folyamatosan ellenőrizzük, hogy ne süljön túl. Akkor tökéletes, ha a teteje megrepedt picit és tapintásra belül még lágy.",
      "Ha elkészült, kivesszük és hagyjuk hűlni.  ",
      "A moussehoz a tejszínből 1,5 dl-t felteszünk melegíteni a sóval együtt egy kis lábasban. ",
      "A csokit felaprítjuk és egy tálba tesszük. A zselatint bő hideg vízbe áztatjuk. ",
      "Amikor a tejszín felforrt, lehúzzuk a tűzről és a kézzel kicsavart zselatint beletesszük. Keverünk rajta néhányat, hogy feloldódjon benne teljesen és óvatosan ráöntjük a tejcsokira. Addig dolgozunk rajta habverővel, amíg szép fényes, homogén állagú krémet nem kapunk. Hozzáadjuk a maracuját is és félre tesszük picit. A maradék 2,5 dl hideg tejszínt habbá verjük robotgép segítségével, vigyázva, hogy ne habosítsuk túl. Ha szép tartása van a habnak, akkor már jó lesz. Egy spatulával a csokis alaphoz forgatjuk úgy, hogy ne essen szét a hab és teljesen összedolgozzuk a két részt. ",
      "A kihűlt tésztákat egy is késsel kivágjuk a formákból. Az egyik karikát kibéleljük egy magasabb csík sütőpapírral vagy torta fóliával, hogy megtartsa valami töltés közben az egészet, hiszen magasabb lesz a süti, mint maga a karika. Az egyik kihűlt tésztát beletesszük a formába és ráhalmozzuk a mousse felét. Utána jöhet a második lap is és végül a maradék krémmel befejezzük. Legalább 2-3 órára hűtőbe tesszük dermedni, de a legjobb, ha egy éjszakát pihentetjük. ",
      "Tálalás előtt levesszük róla a formát, lehúzzuk róla a papírt/fóliát. Egy forró kenőkéssel megigazíthatjuk az oldalát, ha szükséges és friss gyümölccsel dekorálhatjuk. ",
    ]
  ),
];

export const recipesEN: IRecipe[] = [
  new Recipe(
    "grapefruitos-truffelgolyo",
    "Grapefruit truffle balls",
    "assets/images/siena_grapefruitos_truffelgolyo.webp",
    "Preparation time: 15 minutes + 1 hour cooling time",
    [
      new Ingredient("Siena 60% sugar-free dark chocolate", "20 dkg"),
      new Ingredient("Whipped cream", "50 ml"),
      new Ingredient("Freshly squeezed grapefruit juice", "100 ml"),
      new Ingredient("Soft butter", "5 dkg"),
      new Ingredient("A big pinch of salt", "1"),
      new Ingredient("Unsweetened 20-22% Siena cocoa powder for topping", "1"),
    ],
    [
      "Heat the cream and grapefruit juice with the salt in a small pot over medium heat. Break the chocolate into a plastic bowl, ideally into small pieces. When the cream mixture has boiled, remove it from the heat and carefully pour it over the measured chocolate. Mix with a hand whisk until a completely homogeneous cream is obtained. If your hands are warm, add the soft butter, mix until completely smooth and refrigerate for at least 1 hour so that it can be shaped.",
      "Form balls with wet hands. It's okay if they're irregular, they'll look even better in a pile. We roll each one in the cocoa powder, twice, so that the surface is nice.",
    ]
  ),
  new Recipe(
    "kokusz-panna-cotta",
    "Coconut panna cotta, lime, roasted salted cocoa crumbs",
    "assets/images/siena_kokusz_panna_cotta.webp",
    "Preparation time: 30 minutes + setting time",
    [
      new Ingredient("Coconut cream", "6 dl"),
      new Ingredient("Crystal sugar", "7 dkg"),
      new Ingredient("Scoop out the seeds of a vanilla bean", "afraid"),
      new Ingredient("Grated lime zest", "1 pc"),
      new Ingredient("Gelatin", "5 cards"),
      new Ingredient("Salt", "1 large pinch"),
      new Ingredient("Flour", "5 dkg"),
      new Ingredient("Almond flour", "5 dkg"),
      new Ingredient("Cold butter", "5 dkg"),
      new Ingredient("20-22% Siena cocoa powder", "5 dkg"),
    ],
    [
      "For the panna cotta, soak the gelatin in a plastic bowl so that it is covered with ice-cold tap water.",
      "We measure the cream, sugar, vanilla bean seeds, lime peel and salt in a bowl with higher walls. Heat to boiling point, being careful not to burn the sugar. Then take it off the heat and the gelatin, which by then has swollen to a spongy consistency, wring it out by hand and throw it into the hot base. Mix it all together and let it cool a little before pouring it into the mold.",
      "Until then, turn on the oven at 180 degrees for the crispiness. Crumble all the ingredients by hand in a bowl and spread them on a baking sheet lined with baking paper. Place in the oven for 7 minutes. When it is done, turn it over with a fork and add approx. Fry for 3-4 minutes, being careful not to burn. When ready, refrigerate.",
      "As a final step, we distribute the panna cotta in beautiful glasses. Put it in the fridge for at least 2 hours, but the longer it rests, the better. It's good when it vibrates like jelly at room temperature, but melts in your mouth.",
      "When serving, spoon some of the crisps on top.",
    ]
  ),
  new Recipe(
    "csokis-pofeteg",
    "Chocolate puffs",
    "assets/images/siena_csokis_pofeteg.webp",
    "Preparation time: 30 minutes + baking time",
    [
      new Ingredient("Siena hazelnut dark chocolate", "10 dkg"),
      new Ingredient("Soft butter", "5 dkg"),
      new Ingredient("Eggs at room temperature", "1 pc"),
      new Ingredient("Powdered sugar", "7 dkg"),
      new Ingredient("Ground roasted hazelnuts", "5 dkg"),
      new Ingredient("Flour", "7 dkg"),
      new Ingredient("Baking powder", "1 teaspoon"),
      new Ingredient("Salt", "1 large pinch"),
    ],
    [
      "Chop the hazelnut dark chocolate with a sharp knife, then carefully melt it over steam. Beat the soft butter with the sugar and eggs at room temperature. It is best to use a hand whisk. Add the already cooled dark chocolate and then thoroughly mix the mass with all the remaining ingredients. We put it in the fridge to rest for half an hour so that it will be easier to shape.",
      "Form walnut-sized balls with wet hands, roll them in powdered sugar and place them on a baking sheet lined with baking paper, leaving at least 3-4 cm between them.",
      "Place in an oven preheated to 180 degrees and bake for 7-9 minutes. It is important not to overbake, because it will dry out. The texture is perfect when the outer layer is already more solid, but you can feel that it is still soft inside. So it will be perfect after cooling down.",
    ]
  ),
  new Recipe(
    "tart-kosarka",
    "Tart basket with homemade coffee and chocolate cream and raspberries",
    "assets/images/siena_tart_kosarka.webp",
    "Preparation time: 2 hours",
    [
      new Ingredient("Flour", "15 dkg"),
      new Ingredient("Almond flour", "5 dkg"),
      new Ingredient("Cold butter", "10 dkg"),
      new Ingredient("Powdered sugar", "5 dkg"),
      new Ingredient("Salt", "1 pinch"),
      new Ingredient("Ice cold water", "2 tbsp"),
      new Ingredient("Lemon zest", "1 pc"),
      new Ingredient("Fatty milk", "250 ml"),
      new Ingredient("Sugar", "80 g"),
      new Ingredient("Egg yolk", "5 pcs"),
      new Ingredient("Vanilla stick", "1 pc"),
      new Ingredient("Siena 60% dark chocolate", "150 g"),
      new Ingredient("Whole coffee broken", "1 handful"),
      new Ingredient("Frozen raspberries", "20 dkg"),
      new Ingredient("Crystal sugar", "5 dkg"),
      new Ingredient("Starch", "1 tablespoon"),
      new Ingredient("Cold water", "1 dose"),
    ],
    [
      "Crumble the dough ingredients with quick movements, then knead them in a bowl and wrap them in foil and put them in the freezer for at least half an hour.",
      "Then roll out the dough on a lightly floured surface for approx. 5 mm thin and cut into the molds, then put in the freezer for another 30 minutes. This way we don't have to use baking beans for baking.",
      "When it's ready, put it in a preheated 200-degree convection oven and bake it until golden brown in 7-10 minutes.",
      "Remove from the molds while still warm and let cool completely.",
      "For the cream, heat the milk in a saucepan with the salt, vanilla bean seeds, and crushed coffee beans. Meanwhile, beat the egg yolks with the sugar well with a whisk. Add the warm milk little by little, in small portions, to the sugary egg yolk and when it is already lukewarm, i.e. the heat has been equalized, pour it all back into the pan and cook it carefully, stirring, on a low heat until it has the consistency of pudding. Its temperature should not be more than 82 degrees, otherwise we will fry the yolk, it will precipitate and it will become scrambled eggs. You can also work over steam, but then the process takes a little longer. When it is ready, immediately pour it through a sieve into a plastic container and add the chopped dark chocolate, then mix everything until it is homogeneous. As long as it's cold, cover it with foil to keep it fresh so it won't get skinned.",
      "Cook the raspberries with sugar and salt over low heat. Next, mix starch in a small bowl with enough cold water to make it lump-free, then add it to the hot raspberries and boil it for a while. If the fruit was waterier and the sauce even softer, feel free to add more starch. The goal is a thicker jam consistency.",
      "When we are done with all the phases and everything has cooled down, we spread the tart(s) with the raspberry and then fill it with the chocolate cream using a foam bag.",
      "It can be decorated with dark chocolate shavings and fresh raspberries.",
    ]
  ),
  new Recipe(
    "mogyoros-etcsokis-szelet",
    "Sugar and gluten-free hazelnut-dark chocolate bar",
    "assets/images/siena_mogyoros_etcsokis_szelet.webp",
    "Preparation time: 2 hours",
    [
      new Ingredient("Whole egg", "4 pcs"),
      new Ingredient("Roasted and ground hazelnuts", "5 dkg"),
      new Ingredient("Almond flour", "5 dkg"),
      new Ingredient("Rice flour", "45 g"),
      new Ingredient("Erythritis", "50 g"),
      new Ingredient("Salt", "2 large pinches"),
      new Ingredient("Siena sugar-free 60% dark chocolate", "5 dkg"),
      new Ingredient("Egg yolk", "2 pcs"),
      new Ingredient("Whipped-cream", "2,5 dl"),
      new Ingredient("Cold cream", "3 dl"),
      new Ingredient("Mascarpone", "20 dkg"),
      new Ingredient("Siena Duo Mix sugar-free milk cream", "40 g"),
      new Ingredient("Unsweetened American peanut butter", "15 g"),
      new Ingredient("Gelatin", "4 pieces"),
    ],
    [
      "Separate the eggs for the sponge cake. Beat the egg whites with a mechanical whisk. When it starts to thicken, add the sweetener little by little and finally the salt. Mix in the yolks and finally the dry ingredients by hand, but work very carefully so as not to break the foam. Line the tray with baking paper and pile the mixture on it, then spread it evenly with a spreader knife. in a preheated oven at 190 degrees for approx. Bake until ready in 12 minutes, but check with a needle test. When it's ready, take it out of the oven, turn it out of the pan and pull off the parchment paper to prevent the dough from getting wet.",
      "For the chocolate cream, melt the dark chocolate over steam, then set aside to cool. Measure the sweetener and water in a small saucepan and heat it to 118 degrees to make sugar syrup. Meanwhile, whisk the yolks with a pinch of salt with a machine, and when the syrup reaches 118 degrees, carefully add the syrup at low speed. When all the syrup is in it, turn the machine on high and beat it until it cools down. When it's pretty tall and all whitened, it's ready. Separately, whip the cold cream into foam and carefully mix the two bases together. Finally, stir everything into the already cooled melted dark chocolate. Put it in the fridge to set.",
      "For the hazelnut cream, whip 2 dl of cold cream and the mascarpone with a whisk. Add the peanut butter, cream and salt. In a small saucepan, heat 1 dl of cream together with the erythritol, while soaking the gelatin in cold water. If the cream has not yet boiled, remove it from the heat and add the squeezed-out gelatin. Mix it and remove it from the foamed mascarpone cream with 2-3 spoons, which we mix into this hot gelatin base. This is how we balance the heat and make sure that the gelatin does not jump together due to the cold. Then add the gelatin part to the mascarpone cream with a spatula.",
      "We assemble the cookie. Cut the sponge lengthwise into two equal parts with a large, serrated knife.",
      "Place one sheet back in the pan and fill it evenly with the chocolate cream. After that comes the second dough and finally we pile the hazelnut cream on top.",
      "It can be decorated with toasted whole or sliced almonds.",
    ]
  ),
  new Recipe(
    "lisztmentes-csoki-torta",
    "Flourless chocolate cake, passion fruit milk chocolate mousse",
    "assets/images/siena_marajucas_tejcsoki_mousse.webp",
    "Preparation time: 1 hour + cooling, setting",
    [
      new Ingredient("Siena sugar-free 60% dark chocolate", "11 dkg"),
      new Ingredient("Butter", "8,5 dkg"),
      new Ingredient("Salt", "2 large pinches"),
      new Ingredient("Siena 20-22% cocoa powder", "4 dkg"),
      new Ingredient("Almond flour", "5 dkg"),
      new Ingredient("Crystal sugar", "15 dkg"),
      new Ingredient("Egg", "3 pcs"),
      new Ingredient("Siena milk chocolate", "20 dkg"),
      new Ingredient("Whipped cream", "4 dl"),
      new Ingredient("Fresh passion fruit flesh scraped out", "2 pcs"),
      new Ingredient("Gelatin", "5 pieces"),
    ],
    [
      "Fill a medium-sized pot with water about 2 fingers thick and heat it up. We measure the chocolate, butter, and salt in a metal bowl or cauldron, put it on the steam and melt it slowly, stirring occasionally.",
      "We measure the cocoa powder and almonds in a small bowl. When the butter mixture has melted, add the dry ingredients and mix with a hand whisk until lump-free.",
      "Using a food processor, beat the whole eggs together with the sugar until well foamed and finally, carefully, in several steps, fold them into the chocolate part.",
      "Divide into 2 20 cm cake tins and bake in a convection oven preheated to 160 degrees for approx. 12-17 minutes. We constantly check that it does not overcook. It is perfect when the top is slightly cracked and the inside is still soft to the touch.",
      "When ready, take it out and let it cool.",
      "For the mousse, heat 1.5 dl of the cream together with the salt in a small saucepan.",
      "Chop the chocolate and put it in a bowl. Soak the gelatin in plenty of cold water.",
      "When the cream has boiled, remove it from the heat and add the hand-wrung gelatin. We stir some of it so that it dissolves completely and carefully pour it over the milk chocolate. We work on it with a whisk until we get a nice, shiny, homogeneous cream. Add the passion fruit and set aside. Beat the remaining 2.5 dl of cold cream into foam using a food processor, being careful not to over-foam. If the foam has a nice hold, then it will be good. Use a spatula to stir the chocolate base so that the foam does not fall apart and completely combine the two parts.",
      "Cut the cooled pasta out of the molds with a knife. Line one of the rings with a higher strip of baking paper or cake foil to hold it in place while filling, as the cookie will be taller than the ring itself. Place one of the cooled dough in the mold and pile half of the mousse on top. Then the second sheet can be added and finally we finish with the remaining cream. Put it in the fridge for at least 2-3 hours to set, but it is best to let it rest overnight.",
      "Before serving, remove the form and peel off the paper/foil. You can adjust the sides with a hot butter knife if necessary and decorate with fresh fruit.",
    ]
  ),
];

export default class RecipeStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeObservable(this);

    runInAction(() => {
      recipesHU.forEach((recipe) => this.recipesHU.set(recipe.id, recipe));
      recipesEN.forEach((recipe) => this.recipesEN.set(recipe.id, recipe));
    });
  }

  @observable recipesHU: Map<string, IRecipe> = new Map();
  @observable recipesEN: Map<string, IRecipe> = new Map();
  @observable selectedRecipe: IRecipe = null;

  @computed get getRecipesHU(): IRecipe[] {
    return Array.from(this.recipesHU.values());
  }

  @computed get getRecipesEN(): IRecipe[] {
    return Array.from(this.recipesEN.values());
  }

  @action setSelectedRecipeHU = (id: string): Recipe => {
    const selectedRecipe = recipesHU.find((r) => r.id === id);
    runInAction(() => {
      this.selectedRecipe = selectedRecipe;
    });
    return selectedRecipe;
  };

  @action setSelectedRecipeEN = (id: string): Recipe => {
    const selectedRecipe = recipesEN.find((r) => r.id === id);
    runInAction(() => {
      this.selectedRecipe = selectedRecipe;
    });
    return selectedRecipe;
  };
}
