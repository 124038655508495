import React, { useState, useEffect } from "react";
import { animateScroll } from "react-scroll";
import { Link } from "react-router-dom";
import sienaLogo from "../assets/images/logo.png";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const [scroll, setScroll] = useState(0);
  const [top, setTop] = useState(0);
  const { t } = useTranslation();

  useEffect(() => {
    setTop(100);
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    animateScroll.scrollToTop();
  };

  const handleScroll = () => {
    setScroll(window.scrollY);
  };

  return (
    <footer className="footer-section">
      <div className="container">
        <div className="row">
          {/*  Start:About  */}
          <div className="col-lg-4 col-sm-12 d-flex justify-content-center text-center">
            <div className="widget">
              <div className="footer_logo">
                <img
                  className="img-responsive"
                  style={{ height: "110px" }}
                  src={sienaLogo}
                  alt=""
                />
              </div>
              <div className="footer_p">
                <p className="footer_para">{t("footer.description")}</p>
              </div>
            </div>
          </div>
          {/*  End:About  */}

          {/*  Start:Quick Link  */}
          <div className="col-lg-4 col-sm-12 d-flex justify-content-center text-center">
            <div className="widget quick_lnk">
              <h5>{t("footer.navigation")}</h5>
              <ul>
                <li>
                  <Link to={"/"}>
                    {t("footer.navigationElements.homePage")}
                  </Link>
                </li>
                <li>
                  <Link to={"/rolunk"}>
                    {t("footer.navigationElements.aboutUs")}
                  </Link>
                </li>
                <li>
                  <Link to={"/termekek"}>
                    {t("footer.navigationElements.products")}
                  </Link>
                </li>
                <li>
                  <Link to={"/receptek"}>
                    {t("footer.navigationElements.recipes")}
                  </Link>
                </li>
                <li>
                  <Link to={"/kapcsolat"}>
                    {t("footer.navigationElements.contact")}
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          {/*  End:Quick Link  */}

          {/*  Start:Latest post   */}
          {/* <div className="col-lg-3 col-sm-12">
            <div className="widget">
              <h5>Latest Articles</h5>
              <ul className="footer_recent_blog">
                <li>
                  <img
                    className="img-responsive"
                    src="assets/images/blog1.jpg"
                    alt=""
                  />
                  <span className="post_cont">
                    <span className="post-date">
                      <i className="fa fa-calendar"></i>March 14, 2019
                    </span>
                    <Link to={"/single-blog"}>
                      <span>How to Eat Organic Foods</span>
                    </Link>
                  </span>
                </li>
                <li>
                  <img
                    className="img-responsive"
                    src="assets/images/blog2.jpg"
                    alt=""
                  />
                  <span className="post_cont">
                    <span className="post-date">
                      <i className="fa fa-calendar"></i>March 14, 2019
                    </span>
                    <Link to={"/single-blog"}>
                      <span>How to Eat Organic Foods</span>
                    </Link>
                  </span>
                </li>
              </ul>
            </div>
          </div> */}
          {/*  End:Latest post  */}

          {/*  Start:Newsletter  */}
          <div className="col-lg-4 col-sm-12 d-flex justify-content-center text-center">
            <div className="widget">
              <h5>{t("footer.contact")}</h5>
              {/*  Start:Subscribe  */}
              <div className="news_letter_wrp">
                <p>{t("footer.contactElements.phone")}: +36 29 656 120</p>
                <p>
                  {t("footer.contactElements.email")}: info@sienamagyarorszag.hu
                </p>
                <p>
                  {t("footer.contactElements.address")} 1095 Budapest, Ipar utca
                  5.
                </p>
                <div className="container">
                  <div className="row" style={{ justifyContent: "center" }}>
                    <a
                      className="footer_social_custom"
                      href="https://www.facebook.com/sienamagyarorszag/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fa fa-facebook"></i>
                    </a>
                    <a
                      className="footer_social_custom"
                      href="https://www.instagram.com/sienamagyarorszag/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fa fa-instagram"></i>
                    </a>
                  </div>
                </div>
                <div className="widget quick_lnk">
                  <ul>
                    <li>
                      <a
                        className="footer_social_custom"
                        href={t("footer.gtcFile")}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {t("footer.gtc")}
                      </a>
                      <a
                        className="footer_social_custom"
                        href={t("footer.gdprFile")}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {t("footer.gdpr")}
                      </a>
                    </li>
                  </ul>
                </div>
                {/* <div className="footer_socil">
                  <ul className="list-icons link-list footer_soc">
                    <li>
                      <a
                        href="https://www.facebook.com/sienamagyarorszag/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="fa fa-facebook"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.instagram.com/sienamagyarorszag/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="fa fa-instagram"></i>
                      </a>
                    </li>
                  </ul>
                </div> */}
                {/* <form className="mailchimp">
                  <input
                    className="email_field"
                    type="text"
                    name="email"
                    id="subscriber-email"
                    placeholder="Email Address"
                  />
                  <button
                    className="submit-contact"
                    type="submit"
                    id="subscribe-button"
                  >
                    Subscribe
                  </button>
                  <span className="subscription-success"> </span>
                  <span className="subscription-error"> </span>
                  <label
                    className="subscription-label"
                    htmlFor="subscriber-email"
                  ></label>
                </form> */}
                {/*  END MAILCHIMP FORM  */}
              </div>
            </div>
          </div>
          {/*  End:Start Subscribe  */}

          {/* <button
            className={`scrollup ${scroll > top ? "show" : ""}`}
            onClick={() => scrollToTop()}
          >
            <span className="icon-glyph-203"></span>
          </button> */}
        </div>
      </div>
      {/*  Start:Subfooter  */}
      <div className="subfooter">
        <p>
          2022 © <Link to={"/"}>Happy Chocolates Factory Zrt.</Link>{" "}
          <span>{t("footer.rights")}</span>
        </p>
      </div>
      {/* End:Subfooter  */}
    </footer>
  );
};

export default React.memo(Footer);
