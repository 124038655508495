import { action, makeObservable, observable } from 'mobx';
import agent from 'api/agent';
// import { IAdminFrontEndData } from 'models/frontEnd';
import { RootStore } from 'mobx/rootStore';

export default class FrontEndStore {
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        makeObservable(this);

        this.rootStore = rootStore;
    }

    @observable lastPageNumber: number = 0;
    @observable furnitureCount: number = 0;

    @action savePageNumber = (pageNumber: number) => {
		this.lastPageNumber = pageNumber;
	}

    // @observable adminFrontEndData: IAdminFrontEndData | null = null;

    // @action loadAdminFrontEndData = async (): Promise<IAdminFrontEndData> => {
    //     const adminFrontEndData = await agent.FrontEnd.getAdminFrontEndData();
    //     this.adminFrontEndData = adminFrontEndData;
    //     return adminFrontEndData;
    // };

    // getCurrentUsersCompanyFurnitureTypes(
    //     adminFrontEndData: IAdminFrontEndData,
    //     companyId: string,
    // ): { furnitureType: FurnitureType; furniureTypeInString: string }[] {
    //     const currentUsersCompanyFurnitureTypes = adminFrontEndData.furnitureTypesByCompanies[companyId];
    //     const result: { furnitureType: FurnitureType; furniureTypeInString: string }[] = [];
    //     Object.keys(currentUsersCompanyFurnitureTypes).forEach((type) => {
    //         result.push({
    //             furnitureType: FurnitureType[type],
    //             furniureTypeInString: currentUsersCompanyFurnitureTypes[type],
    //         });
    //     });
    //     return result;
    // }
}
