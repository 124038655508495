import { IProduct } from "./product";

export interface ICart {
    items: ICartItem[]
    total: number
}

export class Cart implements ICart {
    items: ICartItem[] = []
    total: number = 0
}

export interface ICartItem {
    product: IProduct;
    price: number;
    quantity: number;
}

export class CartItem implements ICartItem {
    product: IProduct    
    price: number
    quantity: number
}