import Footer from "containers/Footer";
import Menu from "containers/Menu";
import PropTypes from "prop-types";
import React, { Fragment } from "react";

const LayoutOne = ({ children }) => {
  return (
    <Fragment>
      <Menu />
      {children}
      <Footer />
    </Fragment>
  );
};

LayoutOne.propTypes = {
  children: PropTypes.any,
  theme: PropTypes.string,
};

export default LayoutOne;
