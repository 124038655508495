import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const MobileMenu = () => {
  const { t, i18n } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  useEffect(() => {
    const offCanvasNav = document.querySelector("#offcanvas-navigation");
    const offCanvasNavSubMenu = offCanvasNav.querySelectorAll(`.subMenu`);
    const anchorLinks = offCanvasNav.querySelectorAll("a");

    for (let i = 0; i < offCanvasNavSubMenu.length; i++) {
      offCanvasNavSubMenu[i].insertAdjacentHTML(
        "beforebegin",
        `<span class="menuExpand"><i></i></span>`
      );
    }

    const menuExpand = offCanvasNav.querySelectorAll(".menuExpand");
    const numMenuExpand = menuExpand.length;

    for (let i = 0; i < numMenuExpand; i++) {
      menuExpand[i].addEventListener("click", (e) => {
        sideMenuExpand(e);
      });
    }

    for (let i = 0; i < anchorLinks.length; i++) {
      anchorLinks[i].addEventListener("click", () => {
        closeMobileMenu();
      });
    }
  });

  const sideMenuExpand = (e) => {
    e.currentTarget.parentElement.classList.toggle("active");
  };

  const closeMobileMenu = () => {
    const offcanvasMobileMenu = document.querySelector(
      "#offcanvas-mobile-menu"
    );
    offcanvasMobileMenu.classList.remove("active");
  };

  return (
    <div className="offcanvasMobileMenu" id="offcanvas-mobile-menu">
      <button
        className="offcanvasMenuClose"
        id="mobile-menu-close-trigger"
        onClick={() => closeMobileMenu()}
      >
        <i className="icon-glyph-146"></i>
      </button>

      <div className="offcanvasWrapper">
        <div className="offcanvasInnerContent">
          <nav className="offcanvasNavigation" id="offcanvas-navigation">
            <ul>
              <li>
                <Link to="/">{t("menu.homePage")}</Link>
              </li>

              <li>
                <Link to="/rolunk">{t("menu.aboutUs")}</Link>
              </li>

              <li>
                <Link to="/termekek">{t("menu.products")}</Link>
              </li>

              <li>
                <Link to="/receptek">{t("menu.recipes")}</Link>
              </li>

              <li>
                <Link to="/kapcsolat">{t("menu.contact")}</Link>
              </li>
              <li>
                <div onClick={() => changeLanguage("hu")}>
                  <img
                    width="32px"
                    height="32px"
                    src="assets/images/languages/hungary.png"
                  />
                </div>
              </li>
              <li>
                <div
                  style={{ marginTop: "8px" }}
                  onClick={() => changeLanguage("en")}
                >
                  <img
                    width="32px"
                    height="32px"
                    src="assets/images/languages/united-kingdom.png"
                  />
                </div>
              </li>
            </ul>
          </nav>

          {/* Search Form */}
          {/* <div className="offcanvasMobileSearchArea">
            <form action="#">
              <input type="search" placeholder="Search ..." />
              <button type="submit"></button>
            </form>
          </div> */}

          {/* Contact Info  */}

          <div className="header_top_right list-unstyled">
            <ul>
              <li>
                <i className="fa fa-phone"></i> +36 29 656-120
              </li>
              <li>
                <i className="fa fa-envelope"></i> info@sienamagyarorszag.hu
              </li>
              <li>
                <i className="fa fa-globe"></i> 1095 Budapest, Ipar utca 5.
              </li>
            </ul>
          </div>

          {/* Social Icon*/}
          <div className="header_top_left">
            <ul className="header_socil list-inline">
              <li>
                {/* <Link to="#/" className="fa fa-facebook"></Link> */}
                <a
                  // className="footer_social_custom"
                  href="https://www.facebook.com/sienamagyarorszag/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i
                    style={{ color: "black", fontSize: "17px" }}
                    className="fa fa-facebook"
                  ></i>
                </a>
              </li>
              <li>
                {/* <Link to="#/" className="fa fa-instagram"></Link> */}
                <a
                  // className="footer_social_custom"
                  href="https://www.instagram.com/sienamagyarorszag/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i
                    style={{ color: "black", fontSize: "17px" }}
                    className="fa fa-instagram"
                  ></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileMenu;
