import React, { Fragment, lazy } from "react";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { Route } from "react-router-dom";
import NotFound from "pages/NotFound";
import { InteractionType } from "@azure/msal-browser";
import { LayoutOne } from "layouts";

import Home from "pages/Home";
import { scopes } from "config/authConfig";

const routes = [
  {
    exact: true,
    secured: false,
    layout: LayoutOne,
    path: "/",
    component: Home,
  },
  {
    exact: true,
    secured: false,
    path: "/404",
    component: lazy(() => import("pages/NotFound")),
  },
  {
    exact: true,
    secured: false,
    layout: LayoutOne,
    path: "/rolunk",
    component: lazy(() => import("pages/About")),
  },
  {
    exact: true,
    secured: false,
    layout: LayoutOne,
    path: "/receptek",
    component: lazy(() => import("pages/RecipesPage")),
  },
  {
    exact: true,
    secured: false,
    layout: LayoutOne,
    path: "/recept",
    component: lazy(() => import("pages/SingleRecipePage")),
  },
  {
    exact: true,
    secured: false,
    layout: LayoutOne,
    path: "/receptek",
    component: lazy(() => import("pages/RecipesPage")),
  },
  {
    exact: true,
    secured: false,
    layout: LayoutOne,
    path: "/kapcsolat",
    component: lazy(() => import("pages/Contact")),
  },
  {
    exact: true,
    secured: false,
    layout: LayoutOne,
    path: "/termekek",
    component: lazy(() => import("pages/ProductsPage")),
  },
  {
    exact: true,
    secured: false,
    layout: LayoutOne,
    path: "/termek",
    component: lazy(() => import("pages/SingleProductPage")),
  },
  {
    exact: true,
    secured: false,
    layout: LayoutOne,
    path: "/kivansaglista",
    component: lazy(() => import("pages/WishlistPage")),
  },
  {
    exact: true,
    secured: false,
    layout: LayoutOne,
    path: "/kosar",
    component: lazy(() => import("pages/CartPage")),
  },
  {
    exact: true,
    secured: false,
    layout: LayoutOne,
    path: "/penztar",
    component: lazy(() => import("pages/CheckoutPage")),
  },
  {
    exact: true,
    secured: false,
    layout: LayoutOne,
    path: "/ertesites",
    component: lazy(() => import("pages/BackPage")),
  },
];

export const renderRoutes = () => (
  <>
    <Route path="*" element={<NotFound />} />
    {routes.map((route, i) => {
      const Layout = route.layout || Fragment;
      const Component = route.component || Fragment;

      if (route.secured) {
        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            element={
              <MsalAuthenticationTemplate
                interactionType={InteractionType.Redirect}
                authenticationRequest={{ scopes: scopes }}
              >
                <Layout>
                  <Component />
                </Layout>
              </MsalAuthenticationTemplate>
            }
          />
        );
      }

      return (
        <Route
          key={i}
          path={route.path}
          exact={route.exact}
          element={
            <div>
              <Layout>
                <Component />
              </Layout>
            </div>
          }
        />
      );
    })}
  </>
);
