import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const AboutContent = () => {
  const { t } = useTranslation();

  return (
    <section className="about-section">
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <div className="about_img">
              <img
                src={"assets/images/siena_filozofiank.webp"}
                alt="Siena Filozófiánk"
              />
            </div>
          </div>
          <div className="col-md-6 col-sm-12">
            <div className="about_item_tb">
              <div className="about_item_tbcell">
                <div className="base-header base_header_left">
                  {/* <small>Siena</small> */}
                  <h3>{t("homePage.ourphilosophy.title")}</h3>
                </div>
                <div className="about_item">
                  {/* <h4>Természetes ízek</h4> */}
                  <p style={{ textAlign: "justify" }}>
                  {t("homePage.ourphilosophy.description")}
                  </p>
                </div>
                <div className="about_item">
                  <h4>{t("homePage.ourphilosophy.subheader")}</h4>

                  <p style={{ textAlign: "justify" }}>
                  {t("homePage.ourphilosophy.paragraph")}
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/*about_wrp*/}
        </div>
        <div
          className="funfact_wapr row"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <div className="slider_btn">
            <Link
              to="/termekek"
              className="slider_btn_one more-link"
              style={{ background: "#e7b92c", textTransform: "none" }}
            >
              {t("homePage.ourphilosophy.button")}
            </Link>
          </div>
        </div>
        {/* row */}
      </div>
      {/* container */}
    </section>
  );
};

export default AboutContent;
