import React from "react";
import { Link } from "react-router-dom";
import { Navbar, Nav } from "react-bootstrap";
import MobileMenu from "../components/mobile-menu/MobileMenu";
import MobileBtn from "../components/mobile-menu/MobileBtn";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import { scopes } from "../config/authConfig";
import { useRootStore } from "mobx/rootStore";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";

const Menu = () => {
  const { instance } = useMsal();
  const { getWishlistProducts } = useRootStore().wishlistStore;
  const { getCartLength } = useRootStore().cartStore;
  const { t, i18n } = useTranslation();

  const changeLanguage = (lng: any) => {
    i18n.changeLanguage(lng);
  };

  return (
    <div className="menu_area">
      {/* Start: header navigation */}
      <div className="navigation">
        <div className="container">
          <div className="logo">
            <Link to="/">
              <img src="assets/images/logo.png" alt="Siena Logo" />
            </Link>
          </div>

          <div className="meun_wrp">
            <Navbar expand="lg" sticky="top" id="navigation">
              <Nav className="mr-auto">
                <ul>
                  <li className="active">
                    <Link className="menu_item" to="/">
                      {t("menu.homePage")}
                    </Link>
                  </li>
                  <li>
                    <Link className="menu_item" to="/rolunk">
                      {t("menu.aboutUs")}
                    </Link>
                  </li>
                  <li>
                    <Link className="menu_item" to="/termekek">
                      {t("menu.products")}
                    </Link>
                  </li>
                  <li>
                    <Link className="menu_item" to="/receptek">
                      {t("menu.recipes")}
                    </Link>
                  </li>
                  <li>
                    <Link className="menu_item" to="/kapcsolat">
                      {t("menu.contact")}
                    </Link>
                  </li>
                  <li>
                    <div
                      style={{ marginLeft: "30px", cursor: "pointer" }}
                      onClick={() => changeLanguage("hu")}
                    >
                      <img
                        width="32px"
                        height="32px"
                        src="assets/images/languages/hungary.png"
                      />
                    </div>
                  </li>
                  <li>
                    <div
                      style={{ marginLeft: "30px", cursor: "pointer" }}
                      onClick={() => changeLanguage("en")}
                    >
                      <img
                        width="32px"
                        height="32px"
                        src="assets/images/languages/united-kingdom.png"
                      />
                    </div>
                  </li>
                </ul>
              </Nav>
            </Navbar>
          </div>

          {/* Mobile Menu */}

          <MobileBtn />

          <MobileMenu />

          {/* End:  Mobile Menu */}

          {/* Start: Cart  */}
          <div className="header_cart">
            <ul>
              <Link to="/kivansaglista" className="cart-toggler search_icon">
                <li
                  className="header_search"
                  data-toggle="tooltip"
                  data-placement="top"
                  title={t("menu.wishList")}
                >
                  <i className="icon-glyph-52"></i>
                  <span className="number_cart">
                    {getWishlistProducts?.length || 0}
                  </span>
                </li>
              </Link>
              <Link to="/kosar">
                <li
                  className="header_cart_icon"
                  data-toggle="tooltip"
                  data-placement="top"
                  title={t("menu.cart")}
                >
                  <i className="icon-glyph-13"></i>
                  <span className="number_cart">{getCartLength}</span>
                </li>
              </Link>
              <AuthenticatedTemplate>
                <li
                  style={{ cursor: "pointer" }}
                  className="header_cart_icon"
                  onClick={() =>
                    instance.logoutRedirect({ postLogoutRedirectUri: "/" })
                  }
                  data-toggle="tooltip"
                  data-placement="top"
                  title={t("menu.signOut")}
                >
                  <i className="icon-glyph-73"></i>
                </li>
              </AuthenticatedTemplate>
              <UnauthenticatedTemplate>
                <li
                  style={{ cursor: "pointer" }}
                  className="header_cart_icon"
                  onClick={() => instance.loginRedirect({ scopes: scopes })}
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Bejelentkezés"
                >
                  <i className="icon-glyph-2"></i>
                </li>
              </UnauthenticatedTemplate>
            </ul>
          </div>
          {/* End: Cart  */}
        </div>
        {/* container */}
      </div>
      {/* End: header navigation */}
    </div>
  );
};

export default observer(Menu);
