import React, { Component } from "react";
import Slider from "react-slick";
import { useTranslation } from "react-i18next";

const Testimonial = () => {
  const { t } = useTranslation();

  const state = {
    heading: `Fair trade`,
    subHeading: " Client Satisfait ",
    image1: "testimonial1.jpg",
    image2: "testimonial2.jpg",
    image3: "testimonial3.jpg",
    image4: "testimonial4.jpg",
  };

    var settings = {
      dots: false,
      arrows: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    };

    return (
      <section className="testi-section">
        <div className="container">
          <div className="base-header">
            {/* <small>{this.state.subHeading} </small> */}
            <h3>{state.heading} </h3>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12 testi_sing_img">
              <div className="testi_sing_img">
                <img
                  alt="team"
                  className="bounce_animate"
                  src={`assets/images/siena_fairtrade_main.webp`}
                />
                <img alt="team" src={`assets/images/siena_fairtrade_2.webp`} />
                {/* <img
                  alt="team"
                  className="bounce_animate"
                  src={`assets/images/siena_fairtrade.webp`}
                /> */}
                <img alt="team" src={`assets/images/siena_fairtrade_3.webp`} />
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12">
              {/* <Slider {...settings}>{ImageeTestiDataList}</Slider> */}
              <div className="item">
                <div className="testi_wrp">
                  <div className="testi_info">
                    <p style={{ textAlign: "justify", marginRight: "15px" }}>
                    {t("homePage.fairtrade.paragraph")}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
}

export default Testimonial;
