/* eslint-disable @typescript-eslint/ban-types */
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { acquireAccessToken } from "utils/tokenService";
import { IPaginatedDto } from "models/common";
import { IProduct } from "models/product";
import {
  ChocolateSubcategory,
  ProductCategory,
  ProductSubcategory,
} from "models/enums/productCategory";
import { IOrder } from "models/order";
import { Transaction } from "models/simplePay";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

axios.interceptors.request.use(
  async (config: AxiosRequestConfig) => {
    if (typeof config !== "undefined") {
      const accessToken = await acquireAccessToken();

      if (accessToken) {
        config.headers["Authorization"] = `Bearer ${accessToken}`;
      }

      return config;
    }
  },
  (error) => {
    return Promise.reject(error);
  }
);

const responseBody = (response: AxiosResponse) => response?.data;

const requests = {
  get: (url: string) => axios.get(url).then(responseBody),
  post: (url: string, body?: {}) => axios.post(url, body).then(responseBody),
  put: (url: string, body: {}) => axios.put(url, body).then(responseBody),
  del: (url: string) => axios.delete(url).then(responseBody),
};

const Analytics = {
  // getAnalytics: (): Promise<IAnalytics> => requests.get('/analytics/getanalytics'),
};

const Product = {
  getFilteredProductPage: (
    pageNumber: number,
    pageSize: number,
    productName: string,
    category: ProductCategory,
    chocolateSubcategory: ChocolateSubcategory,
    subcategory: ProductSubcategory,
    orderByType: string
  ): Promise<IPaginatedDto<IProduct>> =>
    requests.post(`/product/getfrontendfilteredproductpage`, {
      pageNumber,
      pageSize,
      category,
      chocolateSubcategory,
      subcategory,
      productName,
      orderByType,
    }),
  getProductById: (productId: string): Promise<IProduct> =>
    requests.get(`/product/getproductbyid/${productId}`),
  getMostLikedProducts: (): Promise<IProduct[]> =>
    requests.get("/product/getmostlikedproducts"),
  getRelatedProducts: (
    category: ProductCategory,
    productId: string
  ): Promise<IProduct[]> =>
    requests.get(
      `/product/getrelatedproducts?category=${category}&productId=${productId}`
    ),
};

const Wishlist = {
  getWishlist: (): Promise<IProduct[]> => requests.get(`/wishlist/getwishlist`),
  addProductIdToWishlist: (productId: string) =>
    requests.post("/wishlist/addproducttowishlist", { productId }),
  removeProductFromWishlist: (productId: string) =>
    requests.post("/wishlist/removeproductfromwishlist", { productId }),
};

const Order = {
  createOrder: (order: IOrder): Promise<any> =>
    requests.post("/order/createorder", order),
  getOrderNumber: (): Promise<string> => requests.get("/order/getordernumber"),
};

const SimplePay = {
  startRequest: (transaction: Transaction): Promise<AxiosResponse> =>
    axios.post("/simplepay/sendstart", transaction),
};

const Instagram = {
  getImages: () => {
    const config = {
      baseURL: process.env.REACT_APP_INSTA_BASE_URL,
    };
    const url =
      process.env.REACT_APP_INSTA_LIST_MEDIA_URL +
      process.env.REACT_APP_INSTA_API_TOKEN;
    return axios.get(url, config).then(responseBody);
  },
};

export default {
  Analytics,
  Product,
  Wishlist,
  Order,
  Instagram,
  SimplePay,
};
