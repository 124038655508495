import agent from "api/agent";
import {
  makeObservable,
  observable
} from "mobx";
import { SimplePayService } from "services/simplePayService";
import { RootStore } from "./rootStore";

export default class SimplePayStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeObservable(this, {
      simplePayService: observable,
    });
  }

  simplePayService: SimplePayService = new SimplePayService();
}
