import React, { Component } from "react";
import { useTranslation } from "react-i18next";

const WorkProcess = () => {
  const { t } = useTranslation();

    const workProcesstArrayOne = [
      {
        image: "cocoa.png",
        number: "1",
        title: `${t("homePage.theWayOfChocolate.step1.title")}`,
        description: `${t("homePage.theWayOfChocolate.step1.description")}`,
        arrowIcon: "shape1.png",
      },
      {
        image: "cocoa-bean.png",
        number: "2",
        title: `${t("homePage.theWayOfChocolate.step2.title")}`,
        description: `${t("homePage.theWayOfChocolate.step2.description")}`,
        arrowIcon: "shape2.png",
      },
      {
        image: "dry.png",
        number: "3",
        title: `${t("homePage.theWayOfChocolate.step3.title")}`,
        description: `${t("homePage.theWayOfChocolate.step3.description")}`,
        arrowIcon: "shape1.png",
      },
      {
        image: "beans.png",
        number: "4",
        title: `${t("homePage.theWayOfChocolate.step4.title")}`,
        description: `${t("homePage.theWayOfChocolate.step4.description")}`,
        arrowIcon: "shape1.png",
      },
    ];

    const workProcesstArrayTwo = [
      {
        image: "herbal.png",
        number: "04",
        title: `${t("homePage.theWayOfChocolate.step5.title")}`,
        description: `${t("homePage.theWayOfChocolate.step5.description")}`,
        arrowIcon: "shape1.png",
      },
      {
        image: "cocoa-powder.png",
        number: "04",
        title: `${t("homePage.theWayOfChocolate.step6.title")}`,
        description: `${t("homePage.theWayOfChocolate.step6.description")}`,
        arrowIcon: "shape1.png",
      },
      {
        image: "cocoa-powder-2.png",
        number: "04",
        title: `${t("homePage.theWayOfChocolate.step7.title")}`,
        description: `${t("homePage.theWayOfChocolate.step7.description")}`,
        arrowIcon: "shape1.png",
      },
      {
        image: "chocolate.png",
        number: "04",
        title: `${t("homePage.theWayOfChocolate.step8.title")}`,
        description: `${t("homePage.theWayOfChocolate.step8.description")}`,
        arrowIcon: "shape1.png",
      },
    ];

    return (
      <section className="process-section">
        <div className="container">
          {/* Heading */}
          <div className="base-header">
            <small>{t("homePage.theWayOfChocolate.small")}</small>
            <h3>{t("homePage.theWayOfChocolate.title")}</h3>
            <p style={{ marginTop: "10px", textAlign: "justify" }}>
            {t("homePage.theWayOfChocolate.paragraph")}
            </p>
          </div>
          {/* End: Heading */}

          <div className="row">
            {workProcesstArrayOne.map((valu, i) => {
              return (
                <div className="col-md-3 col-sm-12" key={i}>
                  <div className="process-item">
                    <div className="img_process">
                      <img
                        src={`assets/images/${valu.image}`}
                        style={{ height: "100px" }}
                        alt=""
                      />
                      <div className="angle_icon">
                        <img src={`assets/images/${valu.arrowIcon}`} alt="" />
                      </div>
                    </div>
                    <div className="process_text">
                      <h4>{valu.title}</h4>
                      <p style={{ textAlign: "justify" }}>{valu.description}</p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="row" style={{ marginTop: "10px" }}>
            {workProcesstArrayTwo.map((valu, i) => {
              return (
                <div className="col-md-3 col-sm-12" key={i}>
                  <div className="process-item">
                    <div className="img_process">
                      <img
                        src={`assets/images/${valu.image}`}
                        style={{ height: "100px" }}
                        alt=""
                      />
                      {/* <span>{valu.number}</span> */}
                      <div className="angle_icon">
                        <img src={`assets/images/${valu.arrowIcon}`} alt="" />
                      </div>
                    </div>
                    <div className="process_text">
                      <h4>{valu.title}</h4>
                      <p style={{ textAlign: "justify" }}>{valu.description}</p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    );
}

export default WorkProcess;
