import Base64 from "crypto-js/enc-base64";
import Utf8 from "crypto-js/enc-utf8";
import hmacSHA384 from "crypto-js/hmac-sha384";
import { BackData, Transaction } from "models/simplePay";

const axios = require('axios').default;

export class SimplePayService {
  getSignature(data: string): string {
    return Base64.stringify(
      hmacSHA384(data, process.env.REACT_APP_SIMPLEPAY_MERCHANT_SECRET_KEY)
    );
  }

  startPayment(transaction: Transaction): Promise<any> {
    return axios.post(
      "https://justcors.com/tl_a6acb44/https://sandbox.simplepay.hu/payment/v2/start",
      JSON.stringify(transaction),
      {
        headers: {
          "Content-Type": "application/json",
          "Accept-language": "HU",
          Signature: this.getSignature(JSON.stringify(transaction)),
        },
      }
    );
  }

  backPayment(r: string, s: string): BackData | null {
    const dataString = Utf8.stringify(Base64.parse(r));

    if (this.checkSignature(dataString, s)) {
      return JSON.parse(dataString);
    }

    return null;
  }

  checkSignature(data: string, signatureToCheck: string): boolean {
    const computedSignature = this.getSignature(data);
    return computedSignature === signatureToCheck;
  }

  checkResponseSignature(data: string, signatureToCheck: string): boolean {
    const index = data.indexOf(',"paymentUrl');
    data = data.substring(0, index) + ".0" + data.substring(index);
    const computedSignature = this.getSignature(data);
    return computedSignature === signatureToCheck;
  }
}
