import React from "react";
import { Helmet } from "react-helmet-async";
import Slides from "../components/Slides";
import AboutContent from "../components/AboutContent";
import ProductCategories from "../components/ProductCategories";
import Funfact from "../components/Funfact";
import WorkProcess from "../components/WorkProcess";
import ChooseUs from "../components/ChooseUs";
import BestSellers from "../components/BestSellers";
import GalleryContent from "../components/GalleryContent";
import Testimonial from "../components/Testimonial";
import ClinetCarousel from "../components/ClinetCarousel";
import { useTranslation } from "react-i18next";
import { UnauthenticatedTemplate, useMsal } from "@azure/msal-react";

const Home: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>Siena | {t("menu.homePage")}</title>
        <meta name="description" content="Siena főoldal" />
      </Helmet>
      <Slides />
      <AboutContent />
      <WorkProcess />
      <Funfact
        subTitle={t("homePage.funfact1.subtitle")}
        title={t("homePage.funfact1.title")}
        style={{
          backgroundImage: `url("assets/images/siena_life_is_sweet2.webp")`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
        isContactUsBtn={false}
      />
      <ProductCategories />
      <ChooseUs />
      <BestSellers />
      <Testimonial />
      <GalleryContent />
      <Funfact
        subTitle={t("homePage.funfact2.subtitle")}
        title={t("homePage.funfact2.title")}
        style={{
          backgroundColor: "#623f28",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
        isContactUsBtn={true}
      />
      <ClinetCarousel />
    </>
  );
};

export default Home;
