import { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import { useTranslation } from 'react-i18next'

function CookiePopup() {
  const { t } = useTranslation()
  const [cookies, setCookie] = useCookies(['cookie-policy'])
  const [showPopup, setShowPopup] = useState(false)

  const showCookies = () => {
    const hasAgreedToCookiePolicy = cookies['cookie-policy'] !== undefined
    setShowPopup(!hasAgreedToCookiePolicy)
  }

  useEffect(() => {
    showCookies()
  }, [cookies])

  const handleAccept = () => {
    setCookie('cookie-policy', 'true', { path: '/' })
    setShowPopup(false)
  }

  return (
    <>
      {showPopup && (
        <div className="cookie-popup-content">
          <div className="cookie-popup-content-container">
            <p>
              {t('cookiePopup.disclaimer')}

              <a
                className="cookie-popup-click-here"
                href={t('checkoutPage.gdprFile')}
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('cookiePopup.clickHere')}
              </a>
            </p>
            <div className="cookie-popup-content-accept">
              <button onClick={handleAccept}>
                {t('cookiePopup.acceptButton')}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default CookiePopup
